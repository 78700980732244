<template>
  <div class="flex flex-col items-center justify-center h-screen">
    <div class="bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg p-6 max-w-md w-full">
      <div class="mb-4">
        <h2 class="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2">File Details:</h2>
        <p class="text-sm text-gray-600 dark:text-gray-400">Name: {{ fileInfo.name }}</p>
        <p class="text-sm text-gray-600 dark:text-gray-400">Size: {{ fileInfo.size }} MB</p>
        <p class="text-sm text-gray-600 dark:text-gray-400">Extension: {{ fileInfo.ext }}</p>
        <p v-if="error" class="text-red-600 dark:text-red-400">Error: {{ error }}</p>
      </div>
      <div v-if="error" class="flex justify-center mt-4">
        <button
            @click="closeClicked"
            class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300"
        >
          Close
        </button>
      </div>
      <div v-else class="flex justify-center space-x-4">
        <button
            @click="designClicked"
            :disabled="notForDesign()"
            :class="{ 'bg-gray-300 text-gray-500 cursor-not-allowed': notForDesign(), 'bg-green-500 hover:bg-green-600': !notForDesign() }"
            class="px-4 py-2 text-white rounded transition duration-300"
        >
          Design & Render
        </button>
        <button
            @click="renderClicked"
            class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
        >
          Just Render
        </button>
        <button
            @click="closeClicked"
            class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileView",
  emits: ['designClicked', 'renderClicked'],
  props: {
    fileInfo: {
      type: Object,
      required: true,
    },
    error: {
      type: String,
      required: false
    }
  },
  methods: {
    notForDesign() {
      const invalidExt = ["blend"]
      return invalidExt.includes(this.fileInfo.ext);
    },
    designClicked() {
      this.$emit("designClicked");
    },
    renderClicked() {
      this.$emit("renderClicked");
    },
    closeClicked() {
      // this.$router.push('/new');
      this.$router.go('/new');
      // this.$router.go(this.$router.currentRoute);
    }
  }
};
</script>
