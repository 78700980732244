<template>
  <div class="flex mt-12 items-center justify-center w-auto">
    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
      <div class="flex flex-col items-center justify-center pt-5 pb-6">
        <img class="mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" :src="dropZone" alt="dropZone" />
        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
        <p class="text-xs text-gray-500 dark:text-gray-400">R3D, GLTF, GLB, FBX or Blender file (MAX 150MB)</p>
      </div>
      <input id="dropzone-file" type="file" class="hidden" @change="uploadFileEvt" />
    </label>
  </div>
</template>

<script>
import dropZone from "@/assets/gui/dropzone.svg";

export default {
  name: "UploadPage",
  emits: ['uploadFile'],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropZone,
    };
  },
  methods: {
    uploadFileEvt(file) {
      this.$emit('uploadFile', file)
    }
  }
}
</script>

<style scoped>

</style>
