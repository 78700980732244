<template>
  <div class="mt-14">
    <!--Job Area-->
    <button @click="newJobClicked" type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
      New
    </button>
    <div v-if="haveProduct()">
      <div>
        <DropdownView :items="getProducts()" @callback="productSelected" title="product"/>
      </div>
      <div class="mt-4">
        <JobView :jobs="jobs" />
      </div>
    </div>
    <!--Warning Area-->
    <div v-else>
      <div id="alert-2" class="flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
        <img class="flex-shrink-0 w-4 h-4" :src="warning" alt="warning" />
        <div class="ms-3 text-sm font-medium">
          Please add at least one product in order to proceed.
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import warning from "@/assets/gui/warning.svg"
import JobView from "@/components/JobView.vue";
import DropdownView from "@/components/DropdownView.vue";
import EventBus from "@/utils/event_bus";

export default {
  name: "RenderPage",
  components: {DropdownView, JobView},
  emits: ['showHidePage'],
  props: {
    jobs: {
      type: Array,
      required: true,
      default: () => [],
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      warning: warning,
    };
  },
  methods: {
    productSelected(index) {
      EventBus.emit('change-prod', index);
    },
    haveProduct() {
      const prods = this.getProducts();
      return prods.length > 0;
    },
    getProducts() {
      const prodNames = [];
      if(!this.user || !this.user.user) return prodNames;
      const products = this.user.user.products;
      for (const product of products) {
        prodNames.push(product.prodName);
      }
      return prodNames;
    },
    newJobClicked() {
      this.$router.push('/new');
    }
  },
  mounted() {
    // console.log(this.user);
    // console.error(this.jobs)
  },
  beforeMount() {
  },
  beforeUnmount() {
  },
}

</script>

<style scoped>

</style>
