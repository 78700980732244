<template>

  <section class="bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        <img class="w-8 h-8 mr-2" :src="render" alt="logo">
        Realistic Render
      </a>

      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Sign in to your account
          </h1>
          <form class="space-y-4 md:space-y-6" @submit.prevent="login">

            <div>
              <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username or Email</label>
              <input type="text" id="username" v-model="data" name="username" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@email.com" required="">
            </div>
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
              <input type="password" name="password" id="password" v-model="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input id="remember" aria-describedby="remember" type="checkbox" v-model="remember" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800">
                </div>
                <div class="ml-3 text-sm">
                  <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                </div>
              </div>
              <a href="#" @click.prevent="goToForgotPassword" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
            </div>


            <button
                type="submit"
                class="w-full text-white bg-amber-500 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Sign in
            </button>

            <p class="text-sm font-light text-gray-500 dark:text-gray-400">
              Don’t have an account yet? <a href="#" @click.prevent="goToSignup" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>

  <ErrorComponent :errorMessage="error" @clear-error="clearError" />
</template>

<script>
import render from "@/assets/gui/render.svg";
import ErrorComponent from "@/components/ErrorView.vue";

export default {
  name: "LoginPage",
  components: {ErrorComponent},
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: '',
      password: '',
      render: render,
      remember: false,
      error: undefined
    };
  },
  methods: {
    async login() {
      console.log('Logging in...');
      try {
        const userData = await this.user.login(
            this.data,
            this.password,
            true,
            this.remember
        );
        if(!userData) {
          this.error = "Login failed!";
          return;
        }
        this.$router.push('/'); // dashboard
      }
      catch (error) {
        this.error = error;
      }
    },
    goToSignup() {
      // Navigate to the signup page
      this.$router.push('/signup');
    },
    goToForgotPassword() {
      // Navigate to the forgot password page
      this.$router.push('/forgot-password');
    },
    clearError() {
      this.error = undefined;
    }
  }
};
</script>

<!--<style scoped src="@/styles/login.css"></style>-->
