<template>
  <div class="fixed top-4 right-4 z-50">
    <div class="relative" @mouseenter="showDropdown" @mouseleave="hideDropdown">
      <div class="flex items-center cursor-pointer border border-gray-600 rounded-full p-2">
        <div class="w-8 h-8 flex items-center justify-center bg-gradient-to-br from-orange-500 to-orange-400 rounded-full text-white">
          {{ getAvatar() }}
          <span v-if="isOnline" class="top-0 start-7 absolute w-3.5 h-3.5 bg-green-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
          <span v-else class="top-0 start-7 absolute w-3.5 h-3.5 bg-red-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
        </div>
      </div>

      <div v-show="isDropdownVisible" @mouseenter="cancelHideDropdown" @mouseleave="hideDropdown"
           class="absolute top-full right-0 mt-0 w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg"
           >
        <div class="p-4">
          <div class="flex items-center">
            <div class="w-8 h-8 flex items-center justify-center bg-gradient-to-br from-orange-500 to-orange-400 rounded-full text-white mr-2">
              {{ getAvatar() }}
            </div>
            <span class="text-gray-700 dark:text-white font-bold">{{ getName() }}</span>
          </div>

          <div class="mt-4 text-gray-700 dark:text-white">
            <div class="font-bold">Balance (EUR€):</div>
            <div class="mt-2">{{ getBalance() }}</div>
            <button @click="recharge" class="mt-4 w-full bg-orange-500 text-white font-semibold py-2 px-4 rounded-lg">Recharge</button>
          </div>

          <div class="mt-6 flex justify-between">
            <button @click="openSettings" class="text-gray-700 dark:text-white hover:text-gray-400">Settings</button>
            <button @click="logout" class="text-gray-700 dark:text-white hover:text-gray-400">Logout</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: "ProfileMenuView",
  props: {
    user: {
      type: Object,
      required: true
    },
    isOnline: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const isDropdownVisible = ref(false);
    let hideDropdownTimeout;

    const showDropdown = () => {
      isDropdownVisible.value = true;
    };

    const hideDropdown = () => {
      isDropdownVisible.value = false;
    };

    const cancelHideDropdown = () => {
      clearTimeout(hideDropdownTimeout);
    };

    return {
      isDropdownVisible,
      showDropdown,
      hideDropdown,
      cancelHideDropdown
    };
  },
  methods: {
    getAvatar() {
      const name = this.getName();
      const avatar = name.charAt(0);
      return avatar.toUpperCase();
    },
    getViewName() {
      const str = this.getName();
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getName() {
      return this.user.user.user.userName;
    },
    getBalance() {
      // Implement logic to get balance
      return this.user.user.account.balance;
    },
    openSettings() {
      // Handle settings click
      console.log("Settings");
    },
    recharge() {
      // Handle recharge click
    },
    logout() {
      // Handle logout click
    }
  }
};
</script>
