import axios from "axios";
import NetworkManager from "@/utils/network_manager";
import {ApiPath} from "@/tools/networking/network_tools";

export default class RequestManager {
    constructor(config) {
        this.network = new NetworkManager(config);
    }
    async reqNewJob(cred, renderParams) {
        const uri = this.network.renderUri();
        const params = this.apiParams(cred, "new", undefined, renderParams);
        return await this.post(uri, params);
    }
    async reqSubmitJob(cred, jobID) {
        const uri = this.network.renderUri();
        const params = this.apiParams(cred, "render", jobID);
        return await this.post(uri, params);
    }
    async reqJobResult(cred, jobID) {
        const uri = this.network.renderUri();
        const params = this.apiParams(cred, "result", jobID);
        return await this.post(uri, params);
    }
    apiParams(cred, type, jobID = undefined, renderParams = undefined) {
        return  {
            cred: cred,
            type: type,
            api: "rapi",
            jobID: jobID,
            render: renderParams,
        };
    }
    async login(data, password, isCred = false) {
        const params = {data: data};
        if(isCred) params.cred = password;
        else params.token = password;
        const uri = this.network.uriPath("dbms", ApiPath.Login);
        return await this.post(uri, params);
    }
    async post(uri, data){
        const info = await axios.post(uri, data);
        if(!info) return {msg: "REQUEST_FAILED", data: undefined};
        return info.data;
    }
    async put(uri, contents){
        const headers = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
            const request = await axios.put(uri, contents, headers);
            return (request.status === 200) ? "SUCCESS": "FAILED";
        }
        catch (e) {
            return String(e);
        }
    }
}
