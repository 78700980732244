import '@/style.css';
import App from './App.vue'
import {createApp, ref} from 'vue'
import * as VueRouter from 'vue-router';
import LoginPage from "@/pages/LoginPage.vue";
import {LoadConfig} from "@/config/config";
import UserData from "@/utils/user_data";
import NewPage from "@/pages/NewPage.vue";
import CacheManager from "@/utils/cache_manager";
import RequestManager from "@/utils/request_manager";

const setupApp = async () => {
    const config = await LoadConfig();
    const cache = new CacheManager();
    const request = new RequestManager(config);
    const user = new UserData(cache, request, config);

    // Create a ref for managing the loading state
    const loading = ref(false);

    const routes = [
        {path: '/new', component: NewPage, props: { user: user } },
        {path: '/login', component: LoginPage, props: { user: user } },
    ]

    const router = VueRouter.createRouter({
        history: VueRouter.createWebHashHistory(),
        routes,
    })

    router.beforeEach(async (to, from, next) => {
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        loading.value = true;
        const isAuth = await user.loginFromCache();
        loading.value = false;
        if (requiresAuth && !isAuth) {
            next('/login');
        } else {
            next();
        }
    });
    const app = createApp(App);
    // Register the LoadingView component globally
    app.provide('loading', loading);
    app.provide('user', user);
    app.provide('config', config);
    app.provide('request', request);
    app.use(router);
    app.mount('#app');

    return { app, router };
}

setupApp().then(r => {return r});
