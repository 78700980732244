import axios from "axios";
import jsYaml from "js-yaml";

const path = "./config.yml";

export async function LoadConfig() {
    const response = await axios.get(path);
    if(response.status !== 200) {
        console.error('Error reading config file:', response.statusText);
        return ;
    }
    const data = response.data;
    const config =  jsYaml.load(data);
    setConfigData(config);
    return config;
}

function setConfigData(config) {
    const rest = config.rest;
    const hosts = config.hosts;
    for (const [i, host] of hosts.entries()) {
        let name = host.name;
        let hostName = host.host;
        let domain = host.domain;
        if(!name) hosts[i].name = hostName;
        if(!domain) hosts[i].domain = rest.domain;
    }
}
