<template>
  <div>
    <div class="border-b border-white dark:border-gray-700 cursor-pointer">
      <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        <li class="me-2" v-for="(tab, index) in tabs" :key="index">
          <TabView
              :key="index"
              :icon="tab.icon"
              :title="tab.title"
              :path="tab.path"
              :active="index === activeTabIndex"
              @activateTab="activateTab(index)"
          />
        </li>
      </ul>
    </div>
    <router-view />
  </div>
</template>


<script>
import TabView from "@/components/TabView.vue";

export default {
  name: "TabsView",
  emits: ['setTab'],
  components: {TabView},
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTabIndex: 0
    }
  },
  methods: {
    activateTab(index) {
      this.activeTabIndex = index;
      this.$emit("setTab", index);
      // const tab = this.tabs[index];
      // const path = tab.path;
      // const route = `/${path}`;
      // console.log("route", route);
      // this.$router.push(route);
    },
  },
  mounted() {
  },
};
</script>

<!--<style scoped>-->
<!--.tabs-container {-->
<!--  display: grid !important;-->
<!--  grid-auto-flow: column !important;-->
<!--}-->

<!--</style>-->
