<template>
  <div id="theme-toggle-container" class="fixed bottom-0 right-0 z-50">
    <button id="theme-toggle" type="button" @click="toggleTheme"
            class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none
            rounded-lg text-sm p-2.5">
      <img v-if="darkMode" id="theme-toggle-dark-icon" class="w-5 h-5" :src="dark" alt="dark-icon" />
      <img v-else id="theme-toggle-light-icon" class="w-5 h-5" :src="light" alt="light-icon" />
    </button>
  </div>
</template>

<script>
import dark from "@/assets/gui/dark.svg"
import light from "@/assets/gui/light.svg"

export default {
  name: "ThemeToggle",
  data() {
    return {
      dark: dark,
      light: light,
      darkMode: this.isDarkMode(),
    }
  },
  created() {
    this.setTheme();
  },
  methods: {
    toggleTheme() {
      const darkMode = this.isDarkMode();
      const element = document.documentElement;
      const theme = darkMode ? "light" : "dark";
      if (darkMode) element.classList.remove('dark');
      else element.classList.add('dark');
      localStorage.setItem('color-theme', theme);
      this.darkMode = this.isDarkMode();
    },
    setTheme() {
      if (this.isDarkMode()) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    },
    isDarkMode() {
      return localStorage.getItem('color-theme') === 'dark';
    }
  }
}
</script>
