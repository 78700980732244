<template>
  <div
      class="inline-flex items-center justify-center p-4 border-2 border-transparent bg-gray-600 rounded-t-lg
       hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
      :class="activeBorder()" @click="activateTab">
    <img v-if="icon" :src="getIcon()" alt="tab-icon" class="w-4 h-4 me-2" aria-hidden="true" />
    <a :class="activeTitle()">{{ title }}</a>
  </div>
</template>

<script>
export default {
  name: "TabView",
  props: {
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    path: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getIcon() {
      return `./gui/${this.icon}`;
    },
    activeBorder() {
      return {
        'bg-gray-800': this.active,
        'border-blue-600': this.active,
        // 'border-orange-600': this.active,
        // 'border-dotted': !this.active,
      }
    },
    activeTitle() {
      return {
        'text-white dark:text-white': this.active, // dark theme
      }
    },
    activateTab() {
      this.$emit("activateTab");
    },
  },
};
</script>
