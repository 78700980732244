
export function IsDict(value) {
    const str = value.toString();
    return str.startsWith('{') || str.startsWith('[');
}

export function GetFileInfo(file) {
    const fileSize = Math.round(file.size / (1024*1024));
    const fileName = file.name;
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    return {
        name: fileName,
        size: fileSize,
        ext: String(fileExtension).toLowerCase()
    }
}
