import EventBus from "@/utils/event_bus";

export default class SocketManager {
    constructor(config, userClass) {
        this.__url = null;
        this.cred = {};
        this.__connectCount = 0;
        this.__domain = `render.${config.rest.domain}`;
        this.socket = null;
        this.connected = false;
        this.connecting = false;
        this.isLoggedIn = false;
        this.__userClass = userClass;
    }
    async login(cache, user, product) {
        if(this.connected || !product || !user) return;
        this.cred = {
            insID: 0,
            appKey: user.appKey,
            prodKey: product.prodKey,
        };
        await this.__connect()
    }
    async __connect() {
        this.__url = this.getWUrl();
        this.socket = new WebSocket(this.__url);
        this.initSocket();
    }
    initSocket() {
        this.socket.onopen = this.onOpen.bind(this)
        this.socket.onerror = this.socketError.bind(this);
        this.socket.onmessage = this.onMessages.bind(this);
        this.socket.onclose = this.socketClosed.bind(this);
    }
    onOpen() {
        this.connected = true;
        this.connecting = false;
        this.__connectCount = 0;
        EventBus.emit('user-status', 1);
    }
    onMessages(messageEvent) {
        try {
            const message = messageEvent.data;
            if (!message) return;
            if(!message.startsWith('{')) return;
            const renderInfo = JSON.parse(message);
            const msg = renderInfo.msg;
            const data = renderInfo.data;
            const type = renderInfo.type;
            this.__userClass.onSocketMessage(type, msg, data).then(() => {});
            // this.onSocketMessage(type, msg, data).then();
        }
        catch (e) {
            console.error("ERROR onMessages", e)
        }
    }
    socketClosed(messageEvent) {
        this.connecting = false;
        this.connected = false;
        EventBus.emit('user-status', 0);
        console.log("Socket has been closed!", messageEvent.data)
        // this.socketReconnect();
    }
    socketReconnect() {
        if(this.__connectCount > 3) return;
        console.log("Reconnecting");
        this.__connectCount++;
        this.socket = new WebSocket(this.__url);
        this.initSocket();
    }
    socketError() {
        this.connecting = false;
        EventBus.emit('user-status', 0);
        console.error("There was an error in connecting socket!")
    }
    getWUrl() {
        const cred = this.cred;
        const insID = cred.insID;
        const appKey = cred.appKey;
        const prodKey = cred.prodKey;
        return `wss://${this.__domain}/realAPI?ins_id=${insID}&prod_key=${prodKey}&app_key=${appKey}&exp_from=app`;
    }
    isOnline() {
        return this.connected && this.isLoggedIn;
    }
}
