<template>
    <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      Current Product:
    </label>

    <select
        id="countries"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
         focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
          dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        @change="setSelectedItem($event.target.value)"
    >
      <option
          v-for="item in items"
          :key="item"
          :value="item"
          :selected="item === getSelectedItem()"
      >
        {{ item }}
      </option>
    </select>
</template>

<script>
export default {
  name: "DropdownView",
  emits: ['callback'],
  props: {
    title: {
      type: String,
      required: true,
    },
    cacheItemName: {
      type: String,
      required: false
    },
    cacheManager: {
      type: Object,
      required: false
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  methods: {
    getSelectedItem(){
      const items = this.items;
      if(!items) return `No ${this.title} available`;
      if(!this.cacheItemName || !this.cacheManager) return items[0];
      const cacheIndex = this.cacheManager.getCache(this.cacheItemName);
      if(!cacheIndex || isNaN(cacheIndex)) return items[0];
      if(cacheIndex > 0 && cacheIndex < items.length) return items[cacheIndex];
      return items[0];
    },
    setSelectedItem(item){
      const index = this.items.indexOf(item);
      if(!this.cacheItemName || !this.cacheManager) return this.callBack(index);
      this.cacheManager.setCache(this.cacheItemName, index);
      this.callBack(index);
    },
    callBack(value) {
      this.$emit("callback", value);
    }
  },
  mounted() {
  },
};
</script>
