<template>
    <div v-if="showProg">
      <ProgressView  :progress="progress"/>
    </div>
    <div v-else-if="fileInfo">
      <FileView :fileInfo="fileInfo" :error="fileError" @renderClicked="renderClicked" @designClicked="designClicked" />
    </div>
    <div v-else>
      <UploadPage :user="user" @uploadFile="uploadFile" />
    </div>
</template>

<script>
import warning from "@/assets/gui/warning.svg";
import UploadPage from "@/pages/UploadPage.vue";
import FileView from "@/components/FileView.vue";
import {GetFileInfo} from "@/tools/commons/parse_tools";
import ProgressView from "@/components/ProgressView.vue";

export default {
  name: "NewPage",
  components: {ProgressView, FileView, UploadPage},
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      progress: 0,
      showProg: false,
      warning: warning,
      fileInfo: undefined,
      fileError: undefined,
      localFile: undefined,
    };
  },
  methods: {
    async uploadFile(event) {
      const sizeLimit = 150;
      this.localFile = event.target.files[0];
      this.fileInfo = GetFileInfo(this.localFile);
      const validExt = ["glb", "gltf", "fbx", "r3d", "blend"]
      if(this.fileInfo.size > sizeLimit) this.fileError = `File size exceed ${sizeLimit}MB`;
      else if(!validExt.includes(this.fileInfo.ext)) this.fileError = "Unknown file type!";
    },
    async createNewJob() {
      this.showProg = true;
      this.progress = 10;

      const ext = this.fileInfo.ext;
      let response = await this.user.newJob(ext);
      this.progress = 60;
      if(response.msg !== "SUCCESS") return this.errorReject("ERROR creating URL");

      const file = this.localFile;
      const data = response.data;
      const uploadURL = data.url;
      // TODO: Uploading progress
      response = await this.user.uploadFile(uploadURL, file);
      this.progress = 90;
      if(response !== "SUCCESS") return this.errorReject(`ERROR ${response}`);

      response = await this.user.submitJob(data.jobID);
      if(response.msg !== "SUCCESS") console.error("TODO: ERROR new URL");
      this.progress = 100;
      this.showProg = false;
      this.goBack();
    },
    goBack() {
      this.$router.push('/');
    },
    errorReject(msg) {
      console.error(msg);
      this.goBack();
    },
    async renderClicked() {
      // console.log("renderClicked")
      await this.createNewJob();
    },
    async designClicked() {
      console.log("designClicked")
    },
  }
}
</script>
