<template>
  <div v-if="menuError">
    <ErrorView :errorMessage="menuError" />
  </div>
  <div v-else>
      <div v-if="viewPage">
        <ProfileMenuView :user="user" :isOnline="isOnline" />
        <div class="content-container">
          <TabsView :tabs="tabs" @setTab="setTab" />
        </div>
        <div>
          <RenderPage v-if="tabIndex===0" :user="user" :jobs="jobs" />
        </div>
      </div>
  </div>
</template>


<script>
import RenderPage from "@/pages/RenderPage.vue";
import TabsView from "@/components/TabsView.vue";
import ErrorView from "@/components/ErrorView.vue";
import ProfileMenuView from "@/components/ProfileMenuView.vue";

export default {
  name: "DashboardPage",
  components: {ErrorView, RenderPage, TabsView, ProfileMenuView },
  props: {
    jobs: {
      type: Array,
      required: false,
      default: () => [],
    },
    menuError: {
      type: String,
      required: false,
    },
    user: {
      type: Object,
      required: true,
    },
    isOnline: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      tabs: [
        { icon: "render.svg", title: "Render", path: "render" },
        { icon: "products.svg", title: "Products", path: "products" },
        { icon: "payments.svg", title: "Payments", path: "payments" },
      ],
      tabIndex: 0,
      viewPage: true,
    };
  },
  methods: {
    setTab(index) {
      if(index === this.tabIndex) return;
      this.tabIndex = index;
      console.log(index)
    },
    showDash(value) {
      this.viewPage = value;
    }
  },
  mounted() {
  },
  // beforeMount() {
  //   EventBus.on('show-dashboard', this.showDash);
  // },
  // beforeUnmount() {
  //   EventBus.off('show-dashboard', this.showDash);
  // },
};
</script>

<style scoped>

.content-container {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
