<template>
  <div id="app-body">
    <ThemeToggle />
    <LoadingView :loading="loading" />
    <router-view :isOnline="isOnline" />
    <div v-if="isOnline && isRootRoute()">
      <DashboardPage
          :isOnline="isOnline"
          :menuError="menuError"
          :user="user"
          :jobs="jobs"
      />
    </div>
  </div>
</template>

<script setup>
import ThemeToggle from "@/components/ThemeToggle.vue";
import DashboardPage from "@/pages/DashboardPage.vue";
</script>

<script>
import { useRouter } from 'vue-router';
import EventBus from "@/utils/event_bus";
import LoadingView from "@/components/LoadingView.vue";

export default {
  name: 'App',
  inject: ['user', 'config', 'request', 'loading'],
  components: {LoadingView},
  data() {
    return {
      jobs: [],
      isOnline: false,
      menuError: undefined,
    }
  },
  methods: {
    async onLoggedIn() {
      if(this.isOnline) return;
      await this.user.loginProduct(this);
    },
    isRootRoute() {
      const router = useRouter();
      return router.currentRoute.value.path === '/';
    },
    setMenuError(error) {
      this.menuError = error;
    },
    updateJob(job) {
      // console.error("JOB", job);
      const jobID = job.jobID;
      const count = this.jobs.length;
      if(count === 0) {
        this.jobs = [job];
        return;
      }
      for (let i = 0; i < count; i++) {
        if(this.jobs[i].jobID !== jobID) continue;
        this.jobs[i] = job;
        return;
      }
      this.jobs.push(job);
    },
    async updateProd(ind) {
      this.jobs = [];
      this.isOnline = false;
      await this.user.loginProduct(this, ind);
    },
    updateJobs(jobs) {
      this.jobs = jobs;
    },
    updateUserStatus(status){
      this.isOnline = status === 1;
    }
  },
  beforeMount() {
    EventBus.on('update-job', this.updateJob);
    EventBus.on('update-jobs', this.updateJobs);
    EventBus.on('change-prod', this.updateProd);
    EventBus.on('user-logged-in', this.onLoggedIn);
    EventBus.on('set-menu-error', this.setMenuError);
    EventBus.on('user-status', this.updateUserStatus);
  },

  beforeUnmount() {
    EventBus.off('update-job', this.updateJob);
    EventBus.off('update-jobs', this.updateJobs);
    EventBus.off('user-logged-in', this.onLoggedIn);
    EventBus.off('set-menu-error', this.setMenuError);
    EventBus.off('user-status', this.updateUserStatus);
  },
};
</script>

<style>
body {
  @apply bg-gray-100 dark:bg-sc-dark;
}
</style>
