<template>
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" class="p-4">
          <div class="flex items-center">
            <input
                id="checkbox-all-search"
                type="checkbox"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="checkbox-all-search" class="sr-only">checkbox</label>
          </div>
        </th>
<!--        <th scope="col" class="px-6 py-3">-->
<!--          Scene name-->
<!--        </th>-->
        <th scope="col" class="px-6 py-3">
          Job ID
        </th>
        <th scope="col" class="px-6 py-3">
          Status
        </th>
        <th scope="col" class="px-6 py-3">
          Price
        </th>
        <th scope="col" class="px-6 py-3">
          Action
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="job in jobs"
          :key="job.job_id"
          class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      >
        <td class="w-4 p-4">
          <div class="flex items-center">
            <input
                :id="'checkbox-table-search-' + job.job_id"
                type="checkbox"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
                :for="'checkbox-table-search-' + job.job_id"
                class="sr-only"
            >checkbox</label>
          </div>
        </td>
<!--        <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">-->
<!--          {{ job.name }}-->
<!--        </td>-->
        <td class="px-6 py-4">
          {{ job.jobID }}
        </td>
<!--        <td class="px-6 py-4">-->
        <td class="px-6 py-4">
          <div class="flex items-center">
            <div
                class="h-2.5 w-2.5 rounded-full me-2"
                :class="getStatusClass(job)"></div>{{ getDisplayStatus(job) }}
          </div>
        </td>
        <td class="px-6 py-4">
          {{ job.price }}
        </td>
        <td class="flex items-center px-6 py-4">
          <button
              v-if="isDone(job)"
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
              @click="downloadJob(job)"
          >
            Download
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: "JobView",
  props: {
    jobs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isDone(job) {
      const status = job.status;
      const result = String(job.result);
      return status === 'COMPLETED' && result.startsWith("http");
    },
    getDisplayStatus(job) {
      return this.getStatus(job);
    },
    getStatus(job) {
      const status = job.status;
      let curStatus = status.toString().toUpperCase();
      if(status === 'COMPLETED') if(!this.isDone(job)) curStatus = "FAILED";
      return curStatus;
    },
    getStatusClass(job) {
      const status = this.getStatus(job);
      const allStatuses = ['NEW', 'WAITING', 'FAILED', 'COMPLETED'];
      return {
        'bg-gray-300': status === allStatuses[0],
        'bg-gray-500': status === allStatuses[1],
        'bg-red-500': status === allStatuses[2],
        'bg-green-500': status === allStatuses[3],
        'bg-orange-300': !allStatuses.includes(status),
      };
    },
    downloadJob(job) {
      const url = job.result.toString();
      if(!url.startsWith("http")) console.error("INVALID URL"); //TODO: Toast
      window.location.href = url;
    },
  },
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
