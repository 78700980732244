import EventBus from "@/utils/event_bus";
import SocketManager from "@/utils/socket_manager";

export default class UserData {
    constructor(cache, request, config) {
        this.__cache = cache;
        this.__request = request;
        this.__socket = new SocketManager(config, this);

        this.user = undefined;
        this.__app = undefined;
        this.curProduct = undefined;
    }
    async onSocketMessage(type, msg, data) {
        // console.log(type, msg, data);
        switch (type) {
            case "auth_success":
                this.__socket.isLoggedIn = true;
                break;
            case "auth_failed":
                this.__socket.isLoggedIn = false;
                break;
            case "job":
                this.emitJob(data);
                // this.__app.updateJob(data);
                break;
            case "jobs":
                this.emitJobs(data);
                // this.__app.updateJob(data);
                break;
        }
    }
    async newJob(ext) {
        const socket = this.__socket;
        if(!socket.isOnline()) return;
        const cred = socket.cred;
        const renderParams = {
            ext: ext,
            bake: false,
            output: "PNG",
            expFrom: "app",
        };
        return await this.__request.reqNewJob(cred, renderParams);
    }
    async uploadFile(uri, file) {
        return await this.__request.put(uri, file);
    }
    async submitJob(jobID) {
        const socket = this.__socket;
        if(!socket.isOnline()) return;
        const cred = socket.cred;
        return await this.__request.reqSubmitJob(cred, jobID);
    }
    async jobResult(jobID) {
        const socket = this.__socket;
        if(!socket.isOnline()) return;
        const cred = socket.cred;
        return await this.__request.reqJobResult(cred, jobID);
    }
    emitJob(jobs) {
        EventBus.emit('update-job', jobs);
    }
    emitJobs(jobs) {
        EventBus.emit('update-jobs', jobs);
    }
    async login(data, password, isCred = false, remember = false) {
        const response = await this.__request.login(data, password, isCred);
        const msg = response.msg;
        if(msg !== "SUCCESS") return;
        const userData = response.data;
        this.user = userData;
        await this.onLogin();
        if(remember) this.__cache.setCache('login', {data: data, token: userData.token});
        return userData;
    }
    async onLogin() {
        if(!this.user) return;
        const products = this.user.products;
        if(!products || !products.length) {
            EventBus.emit('set-menu-error', "Please visit 'https://realistic3.com' add at least one product in order to use it.");
            return;
        }
        EventBus.emit('user-logged-in');
    }
    async loginProduct(app, newIndex = undefined) {
        this.__app = app;
        this.__setCacheProduct(newIndex);
        console.log(newIndex, this.curProduct)
        await this.__socket.login(this.__cache, this.user.user, this.curProduct)
    }
    __setCacheProduct(newIndex = undefined) {
        const products = this.user.products;
        if(!products || !products.length) return;
        const cache = this.__cache;
        const index = cache.cacheProduct(newIndex);
        this.curProduct = products[index];
    }
    logOut() {
        this.__cache.remove('login');
        this.user = undefined;
        //TODO: navigate to login
    }
    async loginFromCache(){
        const login = this.__cache.getCache("login");
        if(!login) return false;
        const data = login.data;
        const token = login.token;
        if(!data || !token) return false;
        const user = await this.login(data, token);
        return !!user;
    }
    isLoggedIn() {
        return !!this.user;
    }
}
